import { graphql, Link, StaticQuery } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import React, { useEffect, useRef, useState } from 'react';
import tw, { css, styled } from 'twin.macro';

import Instagram from '../assets/icons/ig.svg';
import Logo from '../assets/images/logo.svg';
import { IS_RELEASED } from '../constants/env';
import { RichText } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { HeaderSettingsQuery } from '../__generated__/types';

type BaseProps = { title: string };
type Props = BaseProps & { data: HeaderSettingsQuery };

const MenuMobileWrapper = tw.div`py-20 lg:py-48 flex items-center lg:w-2/12 xl:w-3/12`;

const LogoWrapper = tw.div`w-6/12 md:w-3/12 lg:w-full`;
const MenuWrapper = tw.ul`w-full lg:w-9/12 xl:w-8/12 md:ml-auto flex flex-col lg:flex-row lg:items-center justify-between`;

const IgMenu = styled(Instagram)`
  text {
    fill: #000000;
  }
`;

function BaseHeader({ data, title: currentTitle }: Props) {
  let [showDockingHeader, setShowDockingHeader] = useState(false);
  let [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down');
  let [showMobileMenu, setShowMobileMenu] = useState(false);
  let headerData = data.allPrismicSiteSettings.edges?.[0]?.node.data;
  let windowScrollY = 0;
  if (typeof window !== `undefined`) {
    windowScrollY = window.scrollY;
  }
  let prev = useRef(windowScrollY);
  if (typeof document !== `undefined`) {
    if (showMobileMenu) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
      //   TODO: Handle when screen > 1024, keep this overflow
    }
  }
  useEffect(() => {
    let onScroll = (e: Event & { currentTarget?: { scrollY: number } }) => {
      setScrollDirection(
        prev.current > e.currentTarget?.scrollY ? 'up' : 'down',
      );
      prev.current = window.scrollY;
      setShowDockingHeader(e.currentTarget?.scrollY > 200);
    };
    window.addEventListener('scroll', onScroll as () => void);
    return () => window.removeEventListener('scroll', onScroll as () => void);
  });

  return (
    <>
      <nav
        css={[
          tw`bg-white transition-all duration-100 ease-in-out w-full absolute`,
          IS_RELEASED && tw`bg-white`,
          scrollDirection === 'down' &&
            showDockingHeader &&
            tw`animate-fadeOutUp opacity-0`,
          scrollDirection === 'up' &&
            showDockingHeader &&
            tw`animate-fadeInDown shadow-md fixed`,
          { zIndex: 6 },
        ]}
      >
        <Wrapper css={tw`flex flex-col lg:flex-row`}>
          <MenuMobileWrapper>
            <LogoWrapper>
              <Link to="/">
                <img
                  src={
                    headerData?.header_logo?.url ||
                    'https://scandidesign.cdn.prismic.io/scandidesign/7437e319-5d8c-4a07-a0ea-e9aa88516f34_logo.svg'
                  }
                />
              </Link>
            </LogoWrapper>
            {IS_RELEASED ? (
              <div css={tw`w-6/12 lg:hidden ml-auto justify-end flex`}>
                <button
                  className={`hamburger hamburger--squeeze ${
                    showMobileMenu ? 'is-active' : ''
                  }`}
                  type="button"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            ) : null}
          </MenuMobileWrapper>
          {IS_RELEASED ? (
            <MenuWrapper
              css={[
                showMobileMenu ? tw`flex animate-fadeInDown` : tw`hidden`,
                tw`lg:flex`,
              ]}
            >
              {headerData.navigation?.map((navData) => {
                const menuDoc = navData?.menu?.document;
                if (menuDoc?.__typename === 'PrismicNavigationMenu') {
                  const instagramLink = headerData?.instagram_url?.url
                    ? headerData.instagram_url.url
                    : null;

                  let menu = menuDoc.data;
                  const isIg = menu.navigation_menu_title === 'Instagram';
                  if (isIg) {
                    return (
                      <MenuItem
                        key={menu.navigation_menu_title}
                        to={instagramLink || '/'}
                        title={
                          !isIg
                            ? menu.navigation_menu_title?.toUpperCase()
                            : undefined
                        }
                        ig
                        isActive={false}
                        closeMobileMenu={() => setShowMobileMenu(false)}
                      />
                    );
                  }

                  let pageDestination = menuDoc.data.page?.document;
                  if (pageDestination?.__typename === 'PrismicPage') {
                    const pageDestinationUid = pageDestination.uid;
                    let to =
                      (pageDestinationUid === 'home'
                        ? ''
                        : `/${pageDestinationUid}`) ?? null;
                    let isActive =
                      currentTitle.toLowerCase() ===
                        pageDestination?.data.title?.text?.toLowerCase() ||
                      currentTitle.toLowerCase() === pageDestinationUid;
                    let subMenu =
                      menu.submenu?.map((submenuSection) => {
                        return {
                          to: `/${pageDestinationUid}#${submenuSection?.section_id}`,
                          title: submenuSection?.submenu_title || undefined,
                          isActive: false,
                        };
                      }) || [];
                    return (
                      <MenuItem
                        key={menu.navigation_menu_title}
                        to={`${to}` || '/'}
                        title={menu.navigation_menu_title?.toUpperCase()}
                        isActive={isActive}
                        ig={false}
                        subMenu={subMenu}
                        closeMobileMenu={() => setShowMobileMenu(false)}
                      />
                    );
                  }
                  return null;
                }
              })}
            </MenuWrapper>
          ) : null}
        </Wrapper>
      </nav>
      <div
        css={[
          tw`w-full h-0 absolute top-0 bg-black bg-opacity-0`,
          tw`transition-all duration-100 ease-in-out delay-75`,
          showMobileMenu && tw`bg-opacity-25 absolute top-0 bottom-0 block`,
          { zIndex: 5 },
          showMobileMenu && tw`h-full`,
          tw`lg:hidden`,
        ]}
      />
    </>
  );
}

export default function Header({ title }: BaseProps) {
  return (
    <StaticQuery<HeaderSettingsQuery>
      query={graphql`
        query HeaderSettings {
          allPrismicSiteSettings {
            edges {
              node {
                data {
                  header_logo {
                    url
                  }
                  navigation {
                    menu {
                      id
                      document {
                        ... on PrismicNavigationMenu {
                          _previewable
                          __typename
                          id
                          data {
                            navigation_menu_title
                            submenu {
                              section_id
                              submenu_title
                            }
                            page {
                              id
                              document {
                                ... on PrismicPage {
                                  _previewable
                                  __typename
                                  id
                                  uid
                                  data {
                                    title {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  instagram_url {
                    target
                    link_type
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <BaseHeader data={data} title={title} />}
    />
  );
}

const StyledAnchorLink = styled(AnchorLink)`
  ${tw`
	font-medium 
	hover:font-bold 
	text-2xs md:text-xs 
	leading-16 md:leading-19 
	tracking-widest 
	flex lg:flex-col 
	transition-all duration-100 ease-in-out 
	items-center lg:items-start 
	hover:cursor-pointer
	mb-16 lg:mb-16
	h-24 lg:h-auto
	relative
	`}
  &:before {
    ${tw`bg-black mr-16 opacity-0 flex self-stretch transition-all duration-300 ease-in-out w-2 font-bold`}

    content: '';
    @media only screen and (min-width: 1024px) {
      ${tw`bg-transparent mr-0 transition-none h-0`}
      display: block;
      content: attr(title);
      overflow: hidden;
      visibility: hidden;
      width: initial;
    }
  }
  &:hover {
    &:before {
      ${tw`opacity-100`}
    }
  }
`;

const StyledLink = styled(Link)`
  ${tw`
	font-medium 
	hover:font-bold 
	text-2xs md:text-xs 
	leading-16 md:leading-19 
	tracking-widest 
	flex lg:flex-col 
	transition-all duration-100 ease-in-out 
	items-center lg:items-start 
	hover:cursor-pointer
	mb-16 lg:mb-0
	h-24 lg:h-auto
	relative
	`}
  &:before {
    ${tw`bg-black mr-16 opacity-0 flex self-stretch transition-all duration-300 ease-in-out w-2 font-bold`}

    content: '';
    @media only screen and (min-width: 1024px) {
      ${tw`bg-transparent mr-0 transition-none h-0`}
      display: block;
      content: attr(title);
      overflow: hidden;
      visibility: hidden;
      width: initial;
    }
  }
  &:after {
    background-color: #000000;
    content: '';
    height: 2px;
    width: 0px;
    ${tw`transition-all duration-100 ease-in-out mt-8`}
  }
  &:hover {
    &:before {
      ${tw`opacity-100`}
    }
    &:after {
      ${tw`lg:w-full`}
    }
  }
`;

const SubMenuItemWrapper = styled.ul`
  ${tw`lg:absolute px-16 lg:pt-16 bg-white`}

  @media only screen and (min-width: 1024px) {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    display: none;
  }
`;

const MenuItemWrapper = styled.li<{ isActive: boolean }>`
  &:hover {
    ${SubMenuItemWrapper} {
      display: block;
    }
  }
  ${StyledLink} {
    ${({ isActive }) => isActive && tw`font-bold`}

    &:before {
      ${({ isActive }) => isActive && tw`lg:opacity-100`}
    }
    &:after {
      ${({ isActive }) => isActive && tw`lg:w-full`}
    }
  }
`;

type MenuItem = { title?: string; ig?: boolean; to: string; isActive: boolean };
const MenuItem = ({
  title,
  to,
  isActive,
  subMenu,
  closeMobileMenu,
}: MenuItem & { subMenu?: Array<MenuItem>; closeMobileMenu: () => void }) => (
  <MenuItemWrapper isActive={isActive}>
    <StyledLink to={to} title={title}>
      {title ?? (
        <IgMenu
          css={css`
            ${tw`h-14 md:h-16`}
            path {
              fill: #000;
            }
          `}
        />
      )}
    </StyledLink>
    {subMenu && subMenu?.length > 1 ? (
      <SubMenuItemWrapper>
        {subMenu?.map((menu) => {
          return (
            <span onClick={closeMobileMenu} key={menu.to}>
              <StyledAnchorLink to={menu.to} title={menu.title}>
                {menu.title?.toUpperCase()}
              </StyledAnchorLink>
            </span>
          );
        })}
      </SubMenuItemWrapper>
    ) : null}
  </MenuItemWrapper>
);
